<script>
  export default {
    data() {
      return {};
    },
    props: {},
    computed: {
      timerToNextReseerv() {
        return this.$store.state.user.secNextReserv;
      },
    },
    methods: {
      close() {
        this.$store.commit("user/changeAlertNextReser", false);
      },
    },
  };
</script>

<template>
  <div class="alertNextReserv">
    <div class="alertNextReserv__circle">
      <div class="alertNextReserv__clockDots timer"></div>
    </div>
    <div class="alertNextReserv__counter">
      <p>Tu proxima reserva inicia en</p>
      <p class="alertNextReserv__timer ">{{ " " + $global.milliSecondsToTime(timerToNextReseerv) }}</p>
    </div>
    <button class="alertNextReserv__btnClose" @click="close">x</button>
  </div>
</template>

<style lang="scss">
  .alertNextReserv {
    @include Flex(row);
    position: fixed;
    top: 100px;
    right: $mpadding/1.5;
    min-height: 60px;
    width: fit-content;
    padding: $mpadding/2 $mpadding/1.5;
    color: white;
    background-color: $primary-color;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
    &__circle {
      @include Flex(row);
      min-width: 32px;
      min-height: 32px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      margin-right: $mpadding/1.5;
    }
    &__clockDots {
      position: relative;
      border: 3px dotted $primary-color;
    }
    &__btnClose {
      font-size: 1.3em;
      padding-left: $mpadding;
      border: none;
      background-color: transparent;
      color: white;
      font-weight: bold;
    }
    &__counter {
      text-align: center;
      position: relative;
    }
    &__timer {
      font-weight: bold;
      animation: counter 1s linear infinite;
    }
    @keyframes lineHour {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  // vars --------------------

  // $size
  $loading__timer--pointer-width: 3px;
  $loading__timer--size: 100%;

  // $speed
  $loading__timer--speed: 3600s;

  // $color
  $loading__timer--color: $primary-color;

  // mixins --------------------

  @mixin animation($_name) {
    animation: #{$_name};
  }

  // keyframes
  @mixin keyframes($_anim-name) {
    @keyframes #{$_anim-name} {
      @content;
    }
  }

  // animation pointer
  @include keyframes(pointer) {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  // timer
  .timer {
    width: $loading__timer--size;
    height: $loading__timer--size;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    &:before,
    &:after {
      content: "";
      height: $loading__timer--pointer-width;
      position: absolute;
      border-radius: 300px;
      background-color: $loading__timer--color;

      top: calc(50% - #{$loading__timer--pointer-width}/ 2);
      left: calc(50% - #{$loading__timer--pointer-width}/ 2);
      transform-origin: $loading__timer--pointer-width/2 $loading__timer--pointer-width/2;
    }

    &:before {
      width: 40%;
      @include animation(pointer $loading__timer--speed linear infinite);
    }

    &:after {
      width: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      @include animation(pointer $loading__timer--speed/60 steps(60) infinite);
    }
  }

  // animation --------------------

  @keyframes pointer {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
